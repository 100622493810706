<template>
  <SearchBox
    :label="label"
    v-model="fulltext"
    name="search"
    :placeholder="$t('common:text-search-placeholder')"
    :on-submit="onSearch"
    :on-clear-search="clearSearch"
    :on-filter="onFilter"
    :on-speech="onSpeech"
  />
</template>

<script setup>
import _ from "lodash-es";
import { ROUTES } from "@utils/routes";
import { useSelectedStore } from '@stores/selected';
import { useCustomerStore } from '@stores/customer';
import { siteSettings } from "@settings/site.settings";
import SearchBox from "@components/ui/search-box";

const windowSize = useWindowSize();

const route = useRoute();
const router = useRouter();
const fulltext = ref(route?.query?.fulltext || '');
const store = useSelectedStore();
const customer = useCustomerStore();

const props = defineProps({
  label: {
    type: String,
    required: true,
    default: () => ''
  }
});

const { $eventBus } = useNuxtApp();

watch(() => route.query.fulltext, (value) => fulltext.value = value);

const routeName = computed(() => route?.name || '');

const onSearch = () => {
  if (route.path === translatePath(ROUTES.HOME)) {
    router.push({
      path: translatePath(ROUTES.ADS),
      query: { fulltext: fulltext.value }
    });
  } else {
    useSearchParam('fulltext', fulltext.value);
  }
};

const clearSearch = () => {
  fulltext.value = '';
  useSearchParam('fulltext', fulltext.value);
}

const onFilter = () => {
  if (windowSize.width.value < 1024) {
    $eventBus.emit('sidebar:open', 'FILTER_VIEW');
  } else if (routeName.value.startsWith('index___')) {
    router.push(`${translatePath(ROUTES.ADS)}`);
    setTimeout(() => {
      $eventBus.emit('focus:filters');
    }, 500);
  } else if (process.client) {
    nextTick(() => {
      window.scrollTo({ top: (document.getElementById('cardsView')?.offsetTop || 0) - 120, behavior: "smooth" });
    });
  }
};

const onSpeech = () => {
  // TODO
  console.log('TODO.search.onSpeech');
};

</script>
